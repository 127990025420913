import React from 'react';
import { Grid } from 'theme-ui';
import BlogPostTile from './BlogPostTile';

const BlogPostGrid = ({ posts }) => (
  <Grid
    columns={[1, 2]}
    gap={5}
    sx={{
      gridColumnStart: [1, null, null, 2],
      gridColumnEnd: [2, 3, 5, 12],
    }}
  >
    {posts.map(node => (
      <BlogPostTile post={node} key={node.id} />
    ))}
  </Grid>
);

export default BlogPostGrid;
