import React from 'react';
import { Link } from 'gatsby';
import { Flex, Text } from 'theme-ui';
import { blogCategoryUrl } from '../lib/urls';

const BlogPostCategories = ({ categories = [] }) => {
  return (
    <Flex>
      {categories
        .filter(category => category)
        .map(category => (
          <Text
            as={Link}
            to={blogCategoryUrl(category.slug?.current)}
            key={category.id}
            sx={{
              color: category.color,
              textTransform: 'uppercase',
              mb: 2,
              mr: 2,
              fontSize: 1,
              fontWeight: 'bold',
              textDecoration: 'none',
            }}
          >
            {category.title}
          </Text>
        ))}
    </Flex>
  );
};

export default BlogPostCategories;
