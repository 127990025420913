/** @jsx jsx */
import { Box, Heading, Text, jsx } from 'theme-ui';
import { Link, graphql } from 'gatsby';
import { FiChevronLeft } from 'react-icons/fi';
import Layout from '../../../components/Layout';
import SEO from '../../../components/SEO';
import BlogPostGrid from '../../../components/BlogPostGrid';

const BlogCategoryTemplate = ({ pageContext, location, data: { posts } }) => {
  return (
    <Layout location={location}>
      <SEO title={`${pageContext.title} | Blog`} />
      <Box
        sx={{
          gridColumnStart: [1],
          gridColumnEnd: [2, 3, 5, 13],
        }}
      >
        <Text
          as={Link}
          to="/blog"
          sx={{
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
            mb: 3,
          }}
        >
          <FiChevronLeft sx={{ mr: 1 }} />
          Blog
        </Text>
        <Heading
          as="h1"
          sx={{
            display: 'flex',
            alignItems: 'center',

            fontSize: [5, 6],
            mb: [0, null, 2, 4],
            textAlign: 'center',
          }}
        >
          {pageContext.title}
        </Heading>
      </Box>
      <BlogPostGrid posts={posts.nodes} />
    </Layout>
  );
};

export const query = graphql`
  query($id: String!) {
    posts: allSanityPost(
      sort: { fields: _createdAt, order: DESC }
      filter: { categories: { elemMatch: { id: { eq: $id } } } }
    ) {
      nodes {
        id
        title
        slug {
          current
        }
        mainImage {
          asset {
            gatsbyImageData(width: 600, height: 600)
          }
        }
        intro
        categories {
          id
          title
          color
          slug {
            current
          }
        }
      }
    }
  }
`;

export default BlogCategoryTemplate;
