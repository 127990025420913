/** @jsx jsx */
import { GatsbyImage } from 'gatsby-plugin-image';
import { jsx } from 'theme-ui';

const BlogPostTileImage = ({ post, bgOffset = 24 }) => {
  if (!post.mainImage) return null;

  const categoryColor = post.categories[0]?.color;

  return (
    <GatsbyImage
      image={post.mainImage.asset.gatsbyImageData}
      sx={{
        borderRadius: 2,
        boxShadow: [
          `${bgOffset / 4}px ${bgOffset / 4}px 0 ${categoryColor}`,
          `${bgOffset / 2}px ${bgOffset / 2}px 0 ${categoryColor}`,
          null,
          `${bgOffset}px ${bgOffset}px 0 ${categoryColor}`,
        ],
        transition: 'all 0.4s',
        '&:hover': {
          boxShadow: [
            null,
            `${bgOffset / 4}px ${bgOffset / 4}px 0 ${categoryColor}`,
            null,
            `${bgOffset / 2}px ${bgOffset / 2}px 0 ${categoryColor}`,
          ],
        },
      }}
      alt=""
    />
  );
};

export default BlogPostTileImage;
